<template>
  <div id="app">
    <b-container fluid>
      <b-row align-v="stretch">
        <b-col xl="5" class="left-container d-none d-xl-block">
          <div class="mini-container">
            <welcome-card></welcome-card>
          </div>
          <policies></policies>
        </b-col>
        <b-col col xl="7" class="right-container d-md-block">
          <div class="logo-container">
            <img class="logo" src="../assets/logo.svg" alt="" />
          </div>
          <div class="contact-title">Contact Us</div>
          <div class="contact-section">
            <div class="label">Contact</div>
            <div class="value">feedback@dib8.app</div>
          </div>
          <div class="contact-section">
            <div class="label">Based in</div>
            <div class="value">Cluj-Napoca, Romania</div>
          </div>
          <b-form @submit="onSubmit" class="contact-form" v-if="!submitting">
            <b-form-input
              id="input-1"
              type="text"
              placeholder="Full Name"
              v-model="contact.fullName"
              required
              class="vertical-space"
            ></b-form-input>
            <b-form-input
              id="input-2"
              type="email"
              placeholder="Email"
              v-model="contact.email"
              required
              class="vertical-space"
            ></b-form-input>
            <b-form-textarea
              id="input-3"
              placeholder="Message"
              v-model="contact.message"
              required
              rows="4"
              max-rows="8"
              class="vertical-space"
            ></b-form-textarea>
            <b-button variant="primary" type="submit" pill>Contact Us</b-button>
          </b-form>
          <div class="loader-container" v-else>
            <b-spinner variant="primary"></b-spinner>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import WelcomeCard from "../components/WelcomeCard.vue";
import Policies from "../components/Policies.vue";
import axios from "axios";
export default {
  components: {
    WelcomeCard,
    Policies,
  },
  data() {
    return {
      submitting: false,
      contact: {
        fullName: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    async onSubmit() {
      this.submitting = true;
      try {
        await axios.post(
          "https://vmi610555.contaboserver.net:5002/api/web/contact/",
          this.contact
        );
        this.submitting = false;
        this.contact = {
          fullName: "",
          email: "",
          message: "",
        };
        this.notify(true);
      } catch (e) {
        this.submitting = false;
        this.notify(false);
      }
    },
    notify(success) {
      this.$bvToast.toast(
        success
          ? "Message was successfully sent."
          : "Message limit exceeded or server is down. Try again later.",
        {
          title: success ? "Success" : "Error",
          variant: success ? "success" : "danger",
          autoHideDelay: 3000,
          toaster: "b-toaster-bottom-center",
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 600px) {
  .logo {
    height: 5.125rem;
  }
}

@media (max-width: 600px) {
  .logo {
    height: 3.625rem;
  }
}

.logo-container {
  text-align: center;
  margin-top: 2rem;
}

.left-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow-y: hidden;
}

.mini-container {
  padding-top: 10vh;
}

.mr-16 {
  margin-right: 1rem;
}

.right-container {
  background-color: #e5f3f7;
  min-height: 100vh;
  overflow-y: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.contact-title {
  margin-top: 2rem;
  font-family: "NunitoBold";
  padding-bottom: 2rem;
  font-size: 2.25rem;
  @media (max-width: 600px) {
    font-size: 1.75rem;
  }
}

.contact-section {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  .label {
    font-family: "NunitoBold";
    font-size: 1.25rem;
  }
  .value {
    font-family: "NunitoLight";
    font-size: 1.25rem;
  }
}

.contact-form {
  width: 500px;
  @media (max-width: 600px) {
    width: 320px;
  }
  margin: 0 auto;
  .vertical-space {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.loader-container {
  height: 300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

